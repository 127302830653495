<!--
 * @Author: your name
 * @Date: 2022-03-14 10:48:15
 * @LastEditTime: 2022-05-18 15:28:43
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\components\commentEdit\commentEdit.vue
-->
<template>
  <div id="comments" class="commentEdit">
    <!-- <el-input
      v-model="commentValue"
      :autosize="{ minRows: 8, maxRows: 4 }"
      type="textarea"
      :placeholder="placeholder"
    >
    </el-input> -->

    <md-editor
      :theme="theme"
      :toolbars="[
        'bold',
        'quote',
        'codeRow',
        'code',
        'unorderedList',
        '-',
        'preview',
        0,
      ]"
      editorId="md-prev1"
      :preview="preview"
      style="height: 200px"
      :placeholder="placeholder"
      v-model="commentValue"
    >
      <template #defToolbars>
        <Editor class="NormalToolbar" title="标记" >
          <template v-slot:trigger>
            <span>123</span>
          </template>
        </Editor>
      </template>
    </md-editor>
    <div class="sendTool">
      <div class="welcome">
        <slot name="welcome"></slot>
      </div>
      <el-button
        class="send"
        type="primary"
        size="small"
        v-if="isLogin"
        @click="submit()"
        >发送</el-button
      >
      <el-button
        class="login"
        type="primary"
        size="small"
        v-if="!isLogin"
        @click="UP_LOGINSHOW(true)"
      >
        请先登陆
      </el-button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { mapMutations, useStore } from "vuex";
import { useGetters } from "../../store/utils/useGetters.js";
import useTheme from "@/hooks/useTheme.js";
export default {
  name: "CommentEdit",
  props: ["placeholder"],
  setup(props, ctx) {
    let commentValue = ref("");
    let preview = ref(false);

    let submit = () => {
      ctx.emit("submitFun", commentValue.value);
      commentValue.value = "";
    };
    return {
      ...useTheme(),
      preview,
      commentValue,
      submit,
      ...useGetters("userInfo", ["isLogin", "username"]),
      ...mapMutations("userInfo", ["UP_ISLOGIN", "UP_LOGINSHOW"]),
    };
  },
};
</script>

<style scoped="scoped">
.sendTool {
  margin-top: 2px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  height: auto;
}

.commentEdit {
  height: auto;
  background-color: var(--white_divBgc);
  color: var(--white_divFc);
}
.welcome {
  font-size: 14px;
}
</style>
