/*
 * @Author: your name
 * @Date: 2022-03-14 13:05:58
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\pages\posts\useReply.js
 */
import { onMounted, ref, nextTick, reactive } from 'vue'
import {
  getComments,
  getCommenteply,
  createComment,
  getImgComment,
  guestbook,
} from '../../api/api.js'

import useLike from '../../components/header/useLike.js'

import { onScrollToElement, sleep } from '../../utils/public.js'
import { ElNotification } from 'element-plus'

export default function (id, typeId,fn) {
  let { addCommemtFn } = useLike()

  let fromId = ref('') //回复者id
  let commentId = ref('') //评论id
  let fromName = ref('') //回复者用户名
  let replys = ref([])
  let comments = ref([])
  let placeholder = ref('请输入您想说的话...')

  // 接受reply ref数组
  const replyRef = reactive({
    data: [],
  })
  // 动态添加ref数组
  let pushRef = el => {
    // 已经存在则跳过
    let flag = replyRef.data.some(item => {
      return item.comment.id == el?.comment.id
    })
    if (el && !flag) {
      replyRef.data.push(el)
    }
  }

  // 滚动到自定评论并打开评论
  let scrollReply = (parentId, childId, replyRef) => {
    console.log(123);
    console.log(parentId, childId, replyRef)
    nextTick(() => {
      let scrollFlag = replyRef.some(item => parentId == item.comment.id)
      if (scrollFlag) {
        replyRef.forEach(async item => {
          if (scrollFlag && parentId == item.comment.id) {
            item.open('parent')
            await sleep(300)
            //获取新增评论的元素的ID
            let elementId = 'Reply' + childId
            // 滚动，节点在可视范围内触发回调
            onScrollToElement(
              elementId,
              () => {
                // 添加动画效果
                // document.getElementById(elementId).setAttribute("class", "replyMian Reply activeComment")
                // setTimeout(() => {
                //   document.getElementById(elementId).setAttribute("class", "replyMian Reply")
                // }, 3000);
              },
              true
            )
          }
        })
      }

      if (!scrollFlag) {
        //获取新增评论的元素的ID
        let elementId = 'Reply' + childId
        // 滚动，节点在可视范围内触发回调
        onScrollToElement(
          elementId,
          () => {
            // 添加动画效果
            // document.getElementById(elementId).setAttribute("class", "replyMian Reply activeComment")
            // setTimeout(() => {
            //   document.getElementById(elementId).setAttribute("class", "replyMian Reply")
            // }, 3000);
          },
          true
        )
      }
    })
  }

  // 评论提交入口
  let submitFun = value => {
    console.log(123)
    let data = {
      type: typeId,
      ownerId: id,
      content: value.toString(),
    }

    if (data.content.trim() == '') {
      ElNotification({
        type: 'error',
        title: `失败！`,
        message: `内容不能为空！`,
        position: 'bottom-right',
      })
      return true
    }

    if (data.content.trim().length > 200) {
      ElNotification({
        type: 'error',
        title: `失败！`,
        message: `字符长度超出！`,
        position: 'bottom-right',
      })
      return true
    }

    if (commentId.value && fromId.value) {
      data.commentId = commentId.value
      data.toId = fromId.value
    }

    //创建评论
    createComment(data).then(res => {
      ElNotification({
        type: 'success',
        title: `成功！`,
        message: `提交成功！`,
        position: 'bottom-right',
      })

      //文章评论的话，给评论数+1
      if (id && typeId === 1) {
        addCommemtFn()
      }
      //图片评论的话，给图片评论数+1
      if (id && typeId === 3) {
        fn && fn('addImgCommemtFn')
      }
      // 文章评论的话，从前面添加评论
      if (!data.toId) {
        comments.value.unshift(res.data)
      }
      // 评论下的评论
      else {
        // 被回复的评论 回复数+1
        comments.value.forEach(item => {
          if (item['id'] == commentId.value) {
            item.commentReplayNum += 1
          }
        })
        // 向该评论下添加评论回复
        if (replys.value[commentId.value]) {
          replys.value[commentId.value].data.unshift(res.data)
          replys.value[commentId.value].status = true
        }
        // dom更新后打开新增评论的二级评论区
        scrollReply(data.commentId, res.data.id, replyRef.data)
      }
      fromId.value = ''
      fromName.value = ''
      placeholder.value = `请输入您想说的话...`
    })
  }

  // 获取回复
  let onSpread = ({ commentId, status }) => {
    // console.log("onSpread 执行力");
    const reply = replys.value[commentId] //当前点开的
    if (reply && reply.data) {
      reply.status = status
      replys.value[commentId] = reply
      // 如果是展开，那么开始滚动
      if (status) {
        // console.log('展开');
        // this.scrollByReply(commentId)
      } else {
        // console.log('关闭');
        console.log(11)
      }
      return
    }

    getCommenteply(commentId).then(res => {
      // console.log('远程获取');
      const reply = res.data.map(c => {
        return c
      })
      replys.value[commentId] = {
        data: reply,
        status: true,
      }
    })
  }

  // 回复
  let onReply = ({ cId, id, name }) => {
    fn && fn('openEdit')
    commentId.value = cId
    fromId.value = id
    fromName.value = name
    placeholder.value = `回复@${name}`
    onScrollToElement(
      'comments',
      async () => {
        let input = document
          .getElementById('comments')
          .getElementsByClassName('textarea-only')
        await sleep(300)
        console.log(input)
        input[0].focus()
      },
      true
    )
  }

  let limit = ref(Number(5)) //偏移量
  let current = ref(Number(1)) // 页码
  let moreFlag = ref(true) //加载更多按钮
  let moreLoading = ref(false) // loading控制
  let withoutFlag = ref(false) //控制没有更多

  // 加载更多方法
  let getMoreComments = async () => {
    moreLoading.value = true
    await sleep(300) //延迟300毫秒 加载loading
    current.value++
    moreFlag.value = false

    // 有id代表是评论
    if (id) {
      if (typeId == 3) {
        getImgCommentFn()
      } else {
        getComment()
      }
    }
    // 否则是留言
    else {
      getGuestbook()
    }
    moreLoading.value = false
  }

  // 获取留言
  let getGuestbook = async () => {
    let { data: guestbooks, pageSize } = await guestbook({
      limit: limit.value,
      current: current.value,
    })
    comments.value.push(...guestbooks)
    if (current.value < pageSize) {
      moreFlag.value = true
    } else {
      moreFlag.value = false
      withoutFlag.value = true
    }
  }

  // 获取文章评论
  let getComment = async () => {
    let { data: comment, pageSize } = await getComments({
      id,
      limit: limit.value,
      current: current.value,
    })
    comments.value.push(...comment)
    if (current.value < pageSize) {
      moreFlag.value = true
    } else {
      moreFlag.value = false
      withoutFlag.value = true
    }
  }

  // 获取文章评论
  let getImgCommentFn = async () => {
    let { data: comment, pageSize } = await getImgComment({
      id,
      limit: limit.value,
      current: current.value,
    })
    comments.value.push(...comment)
    if (current.value < pageSize) {
      moreFlag.value = true
    } else {
      moreFlag.value = false
      withoutFlag.value = true
    }
  }

  onMounted(async () => {
    // 有id代表是评论
    if (id) {
      if (typeId == 3) {
        getImgCommentFn()
      } else {
        getComment()
      }
    }
    // 否则是留言
    else {
      getGuestbook()
    }
  })


  return {
    scrollReply,
    replyRef,
    submitFun,
    comments,
    onSpread,
    replys,
    onReply,
    placeholder,
    getMoreComments,
    moreFlag,
    moreLoading,
    pushRef,
    limit,
    current,
    withoutFlag,
    onScrollToElement,
    sleep,
  }
}
