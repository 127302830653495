<template>
  <div class="replyMian" :id="'Reply' + comment.id">
    <div class="reply">
      <div class="replyTop">
        <div class="top_left">
          <el-image
            :src="comment.avatar"
            @click="toUser(comment.fromId)"
          ></el-image>
        </div>
        <div class="top_middle">
          <div class="top_middleTop">
            <span class="FromName">
              {{ comment.fromName }}
            </span>
            <el-button
              type="text"
              size="small"
              @click="reply(comment.id, comment.fromId, comment.fromName)"
              >回复
            </el-button>
          </div>
          <div class="top_middleBot">
            {{ comment.created_at }}
          </div>
        </div>
        <div class="top_right"></div>
      </div>
      <div v-if="comment.toName" class="replyBotToName">
        回复 <span>@{{ comment.toName }}</span>
      </div>
      <div class="replyBot">
        <md-editor
          :theme="theme"
          editorId="aa"
          :previewOnly="true"
          v-model="commentDetail.content"
        />
      </div>
    </div>
    <!-- 楼中楼插槽 -->
    <slot name="reply"></slot>
    <!-- 展开/折叠更多回复 -->
    <div class="moreDiv" v-if="comment.commentReplayNum > 0">
      <el-button
        type="text"
        size="small"
        v-if="!spreadStatus && comment.commentReplayNum > 0"
        @click="open"
      >
        展开{{ comment.commentReplayNum }}条回复</el-button
      >
      <el-button type="text" size="small" v-else @click="open"
        >收起回复</el-button
      >
    </div>
  </div>
</template>

<script>
import { ref, provide, inject, toRefs } from "vue";
import useTheme from "@/hooks/useTheme.js";
import { useRouter } from "vue-router";
export default {
  props: ["comment", "isComment"],
  setup(props, ctx) {
    let parentId = ref("");
    let { isComment:isCommentProp } = toRefs(props);
    let { comment: commentDetail } = toRefs(props);
    let router = useRouter();
    let spreadStatus = ref(false);

    // 获取祖宗评论id
    provide("parentId", props.comment.id);

    if (!isCommentProp.value) {
      parentId.value = inject("parentId");
    }

    // 展开/关闭 回复
    let open = (type) => {
      // 如果不是父级触发
      if (type != "parent") {
        spreadStatus.value = !spreadStatus.value;
      }
      // 如果使父级触发，则始终展开
      else {
        spreadStatus.value = true;
      }

      ctx.emit("spread", {
        commentId: props.comment.id,
        status: spreadStatus.value,
      });
    };

    // 回复评论
    let reply = (commentId, fromId, fromName) => {
      let obj;
      if (isCommentProp.value) {
        // 回复本条评论
        obj = {
          cId: commentId,
          id: fromId,
          name: fromName,
        };
      } else {
        // 回复本条回复
        obj = {
          cId: parentId.value,
          id: fromId,
          name: fromName,
        };
      }
      ctx.emit("reply", obj);
    };

    let toUser = (id) => {
      router.push({ path: `/user/${id}` });
    };

    return {
      commentDetail,
      isCommentProp,
      toUser,
      ...useTheme(),
      spreadStatus,
      open,
      reply,
    };
  },
};
</script>

<style scoped="scoped" lang="scss">
/* .replyMian:nth-last-child() {
  border-bottom: 1px solid red;
} */
.activeComment {
  animation: active 1s infinite;
}

@keyframes active {
  0% {
    border-bottom: 1px solid #eee;
  }
  100% {
    border-bottom: 1px solid #000;
  }
}

.replyBotToName {
  padding: 10px 10px 0px 20px;
  font-size: 14px;
  color: var(--white_divFc);
}

.replyBotToName span {
  color: #ff8215;
}

.replyBot {
  flex: 1;
  padding: 20px 10px 20px 20px;
  color: var(--white_divFc);
  font-size: 15px;
  height: auto !important;
}

.reply {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.replyTop .top_left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top_left .el-image {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  cursor: pointer;
}

.replyTop {
  height: 100%;
  display: flex;
}

.top_middleTop {
  margin-top: 5px;
  font-size: 16px;
  color: var(--white_divFc);
  .el-button {
    margin-left: 5px;
  }
  .FromName {
  }
}

.top_middleBot {
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--white_divFc);
}

@media screen and (max-width: 768px) {
  .top_left .el-image {
    width: 42px;
    height: 42px;
    cursor: none;
  }
  .top_middle {
    margin-left: 10px;
  }
}

.top_middle {
  flex: 6;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top_right {
  flex: 2;
  height: 100%;
}

.moreDiv {
  padding: 0px 20px 20px 20px;
}
</style>
